<!-- <div *ngIf="dataService.loading" class="k-i-loading"></div> -->
<!-- Terms and conditions -->
<app-terms
  [liveTermsContent]="termsContent"
  [forAdmin]="false"
  [openTerms]="openTerms"
  (accept)="acceptHandler()"
  (cancel)="cancelHandler()"
></app-terms>

<div class="form-group row" *ngIf="this.parker">
  <h1 style="margin: 0 auto">
    Welcome {{ parker?.firstName + " " + parker?.lastName }}
  </h1>
</div>

<div
  class="form-group row"
  *ngIf="this.parker && this.parker.acceptTermsRequired"
>
  <mat-card class="col-12">
    <div class="alert alert-warning" role="alert">
      Please accept Parking Guidelines in order to park at HMRI
    </div>
    <button
      class="col-12"
      mat-button
      color="primary"
      (click)="openTermsContent()"
    >
      View Parking Guidelines to Continue
    </button>
  </mat-card>
</div>

<div
  class="form-group row"
  *ngIf="this.parker && !this.parker.acceptTermsRequired"
>
  <mat-card class="col-12">
    <mat-card-header>
      <mat-card-title>My Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Card ID</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.cardId"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.firstName + ' ' + parker?.lastName"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.email"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Company</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.employer"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Phone</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.mobile"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Category</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.parkingCategory?.name"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Type</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            [value]="parker?.parkerType?.name"
            readonly
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Balance (AUD)</label>
        <div class="col-sm-10">
          <!-- <kendo-numerictextbox
            class="form-control"
            [format]="'c0'"
            [min]="0"
            [value]="parker?.currentBalance"
            [readonly]="true"
          ></kendo-numerictextbox> -->
          <input
          type="text"
          class="form-control"
          [value]="parker?.currentBalance | currency: 'AUD'"
          readonly
        />
        </div>
      </div>
      <div class="alert alert-info" role="alert">
        If any details are incorrect, you wish to dispute a park, or you have
        any questions, please contact HMRI Reception.
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div
  class="form-group row"
  *ngIf="this.parker && !this.parker.acceptTermsRequired"
>
  <mat-card class="col-12" #appendContainer>
    <mat-card-header>
      <mat-card-title>Deposit Funds</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-group row" [formGroup]="depositForm">
        <input type="hidden" formControlName="parkerCardId" />
        <label class="col-sm-12 col-md-3"
          >Amount (min: 10 AUD, max: 100 AUD)</label
        >
        <div class="col-sm-12 col-md-2">
          <kendo-numerictextbox
            style="width: 100%"
            formControlName="amount"
            [min]="10"
            [max]="100"
            [autoCorrect]="true"
            [format]="'c2'"
          ></kendo-numerictextbox>
        </div>
        <div class="col-sm-12 col-md-7" *ngIf="depositForm.valid">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div
  class="form-group row"
  *ngIf="this.parker && !this.parker.acceptTermsRequired"
>
  <mat-card class="col-12">
    <mat-card-header>
      <mat-card-title>Download</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button
        class="col-12 col-md-6"
        mat-button
        color="primary"
        (click)="downloadParkingEntries()"
      >
        Parking History
      </button>
      <button
        class="col-12 col-md-6"
        mat-button
        color="primary"
        (click)="downloadTransactionHistories()"
      >
        Transaction History
      </button>
    </mat-card-content>
  </mat-card>
</div>
