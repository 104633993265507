import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, BehaviorSubject } from "rxjs";
import { tap, map } from "rxjs/operators";

import { GridDataResult } from "@progress/kendo-angular-grid";
import {
    State,
    toDataSourceRequestString,
    translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

@Injectable({
    providedIn: "root",
})
export class TransactionGridService {
    public loading: boolean;
    transactions: BehaviorSubject<any>  = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
        //super([]);
    }

    private BASE_URL = "api/TransactionHistories";
    private data: any[] = [];
    public state: State = {
        skip: 0,
        take: 20,
        filter: { filters: [], logic: "or" },
        group: [],
        sort: [],
    };

    public read(parkerId: number) {
        if (this.data.length) {
            return this.transactions.next(this.data);
            //return super.next(this.data);
        }

        this.fetch(parkerId)
            .pipe(
                tap((data) => {
                    this.data = data;
                })
            )
            .subscribe((data) => {
                //super.next(data);
                this.transactions.next(this.data);
            });
    }

    public fetch(parkerId: number, dataItem?: any, action: string = "grid"): Observable<any> {
        this.loading = true;
        switch (action) {
            case "grid": {
                const queryStr = `${toDataSourceRequestString(this.state)}`;
                const hasGroups = this.state.group && this.state.group.length;

                return this.http.get(`${this.BASE_URL}/GridFetch?${queryStr}`, {
                    params: new HttpParams().set('parkerId', parkerId.toString())
                }).pipe(
                    // Process the response.
                    map(
                        ({ data, total }: GridDataResult): GridDataResult => {
                            return {
                                data: hasGroups ? translateDataSourceResultGroups(data) : data,
                                total: total,
                            };
                        }
                    ),
                    tap(() => this.loading = false)
                );
            }
            case "create": {
                return this.http.post(`${this.BASE_URL}`, dataItem);
            }
            case "edit": {
                return this.http.put(`${this.BASE_URL}/${dataItem.id}`, dataItem);
            }
            case "delete": {
                const options = {
                    headers: {},
                    body: dataItem,
                };

                return this.http.delete(`${this.BASE_URL}/${dataItem.id}`, options);
            }
        }
    }

    // public save(dataItem: any) {
    //     this.fetch(dataItem, 'edit').subscribe(() => this.read(), () => this.read());
    // }
}
