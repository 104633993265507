import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { NotificationService } from "@progress/kendo-angular-notification";
import { State } from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { PageHeaderService } from "src/api-authorization/page-header.service";
import { ParkerGridService } from "src/api-authorization/parker-grid.service";
import { SpinnerService } from "src/api-authorization/spinner.service";
import { Notifier } from "src/app/shared-module/notifier";
import {
    ActivateTermsCommand,
    DraftTermsCommand,
    Parker,
    ParkersClient,
    ParkingEntriesClient,
    ProfileUpsertCommand,
    ReminderCommand,
    SwaggerException,
    TermsAndConditionsContent,
    TermsConditionsClient,
    TransactionHistoriesClient,
} from "src/app/web-api-client";
import { DialogTypes } from "../dialog-type.constants";
import { IParkerDetailDialog } from "../parker-detail-dialog/parker-detail-dialog.model";

@Component({
    selector: "app-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.css"],
    providers: [NotificationService]
})
export class MainComponent implements OnInit {
    @ViewChild('appendContainer', { read: ViewContainerRef }) public container: ViewContainerRef;
    openTerms: boolean = false;
    termsContent: TermsAndConditionsContent = new TermsAndConditionsContent();
    termsContentLive: TermsAndConditionsContent = new TermsAndConditionsContent();
    today: Date = new Date();
    exportForm: FormGroup = new FormGroup({
        useDateRange: new FormControl(false),
        from: new FormControl(new Date()),
        to: new FormControl(new Date()),
        transactionHistoryChecked: new FormControl(true),
        parkingHistoryChecked: new FormControl(true),
    });
    topFilterForm: FormGroup = new FormGroup({
        showSelect: new FormControl(null),
        negativeBalance: new FormControl(false),
    });
    view: Observable<any>;
    formGroup: FormGroup;
    state: State = {
        skip: 0,
        take: 100,
        filter: { filters: [], logic: "or" },
        group: [],
        sort: [],
    };
    pagesizes = [5,10,15,20, 25,30];

    parkersSelection: number[] = [];

    generalCommand: IParkerDetailDialog;
    openGeneralForm = false;

    constructor(
        private termsClient: TermsConditionsClient,
        private pageService: PageHeaderService,
        public dataService: ParkerGridService,
        private router: Router,
        private parkersClient: ParkersClient,
        private transactionsClient: TransactionHistoriesClient,
        private parkingEntriesClient: ParkingEntriesClient,
        private notificationService: NotificationService,
        private spinnerService: SpinnerService
    ) {
        //this.loading = dataService.loading;
        this.termsClient.getDraftTerms().subscribe(
            (result) => this.termsContent = result? result: this.termsContent,
            (error) => console.error(error)
        );
        this.termsClient.getTerms().subscribe(
            (result) => this.termsContentLive = result ? result: this.termsContentLive,
            (error) => console.error(error)
        );
    }

    ngOnInit(): void {
        this.pageService.sendMessage({ name: "HMRI Parking Manager" });
        this.view = this.dataService.parkers;
        this.dataService.read();
    }

    dataStateChange(state: DataStateChangeEvent): void {
        console.log("State change");
        this.dataService.state = state;
        this.state = state;
        this.dataService.read();
    }

    openTermsContent() {
        this.openTerms = true;
    }

    cancelTermsHandler() {
        this.openTerms = false;
    }

    saveDraftTermsHandler(content: TermsAndConditionsContent) {
        // Save here
        this.spinnerService.sendMessage(true);
        this.termsClient.saveDraftTerms(DraftTermsCommand.fromJS(new DraftTermsCommand({
            id: content.id,
            description: content.description,
        }))).subscribe(
            (termsId) => {
                content.id = termsId;
                this.termsContent = content;
                this.openTerms = false;
                this.spinnerService.sendMessage(false);
                Notifier.Success(
                    this.notificationService,
                    this.container,
                    `Draft T/C has been saved.`,
                    'top',
                    //window.innerWidth
                );
            },
            (error) => {
                console.log(error);
                this.spinnerService.sendMessage(false);
            }
        );
    }

    notifyParkerHandler(content: TermsAndConditionsContent) {
        this.spinnerService.sendMessage(true);
        this.termsClient.activateTerms(ActivateTermsCommand.fromJS(new ActivateTermsCommand({
            id: content.id,
        }))).subscribe(
            () => {
                this.openTerms = false;
                this.spinnerService.sendMessage(false);
                Notifier.Success(
                    this.notificationService,
                    this.container,
                    `An email has been sent to most recent parkers.`,
                    'top',
                );
                this.termsContentLive = content;
                this.termsContent = new TermsAndConditionsContent();
                
            },
            (error) => console.log(error)
        );
    }

    sendReminder() {
        if (this.parkersSelection.length < 1) {
            Notifier.Error(
                this.notificationService,
                this.container,
                `Please select at least one parker.`,
                'top',
            );
            return;
        }

        this.spinnerService.sendMessage(true);
        this.parkersClient.sendReminders(
            ReminderCommand.fromJS(new ReminderCommand({parkerIds: this.parkersSelection})))
            .subscribe(() => {
                this.spinnerService.sendMessage(false);
                Notifier.Success(
                    this.notificationService,
                    this.container,
                    `A reminder has been sent to the selected parkers.`,
                    'top',
                );
            },
            (error) => {
                console.error(error);
                this.spinnerService.sendMessage(false);
                Notifier.Error(
                    this.notificationService,
                    this.container,
                    `Failed to send a reminder to the selected parkers.`,
                    'top',
                );
            });
    }

    exportSelectedParkers() {
        if (!this.validateExportHistorySelection()) {
            Notifier.Error(
                this.notificationService,
                this.container,
                `Please select a type of history to export.`,
                'top',
            );
            return;
        }

        if (this.parkersSelection.length < 1) {
            Notifier.Error(
                this.notificationService,
                this.container,
                `Please select at least one parker.`,
                'top',
            );
            return;
        }

        if (this.exportForm.get('useDateRange').value) {
            if (!this.validateDateRange()) {
                Notifier.Error(
                    this.notificationService,
                    this.container,
                    `Please select a date range.`,
                    'top',
                );
                return;
            }

            if (this.exportForm.get('transactionHistoryChecked').value) {
                this.transactionsClient
                    .downloadAllTransactionHistoriesFromRangeForList(
                        this.exportForm.get('from').value, this.exportForm.get('to').value, this.parkersSelection)
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }

            if (this.exportForm.get('parkingHistoryChecked').value) {
                this.parkingEntriesClient
                    .downloadAllParkingEntriesFromRangeForList(
                        this.exportForm.get('from').value, this.exportForm.get('to').value, this.parkersSelection)
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        } else {
            if (this.exportForm.get('transactionHistoryChecked').value) {
                this.transactionsClient
                    .downloadAllTransactionHistoriesForList(this.parkersSelection)
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }

            if (this.exportForm.get('parkingHistoryChecked').value) {
                this.parkingEntriesClient
                    .downloadAllParkingEntriesForList(this.parkersSelection)
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        }
    }

    registerNewParkerClick() {
        this.openGeneralForm = true;
        const command: IParkerDetailDialog = {
            comment: "",
            type: DialogTypes.RegisterNewParker,
        };

        this.generalCommand = command;
    }

    cancelGeneralFormHandler() {
        this.openGeneralForm = false;
        this.generalCommand = null;
    }

    saveGeneralFormHandler(command: IParkerDetailDialog) {
        this.spinnerService.sendMessage(true);
        const type = command.type;
        switch (type) {
            case DialogTypes.RegisterNewParker:
                this.registerNewParker(command);
                break;
            case null || undefined:
                throw new Error("Should not open dialog.");
        }
    }

    private registerNewParker(command: IParkerDetailDialog) {
        const registerCommand = new ProfileUpsertCommand({
            cardId: command.cardId,
            email: command.email,
            firstname: command.firstName,
            lastname: command.lastName,
            mobile: command.mobile,
            employer: command.employer,
            parkingCategory: command.category.name,
            parkingLicense: command.parkerType.name,
            comment: command.comment,
        });
        this.parkersClient
            .registerParker(ProfileUpsertCommand.fromJS(registerCommand))
            .subscribe(
                (newBalance) => {
                    this.dataService.read();
                    this.closeRegisterParkerDialog();
                    Notifier.Success(
                        this.notificationService,
                        this.container,
                        `Parker has been registered.`,
                        'top',
                        //window.innerWidth
                    );
                },
                (error: SwaggerException) => {
                    console.error(error);
                    this.closeRegisterParkerDialog();
                    Notifier.Error(
                        this.notificationService,
                        this.container,
                        `Failed to register parker. ${error}`,
                        'top',
                    );
                }
            );
    }

    exportAllParkers() {
        if (!this.validateExportHistorySelection()) {
            Notifier.Error(
                this.notificationService,
                this.container,
                `Please select a type a history to export.`,
                'top',
            );
            return;
        }

        if (this.exportForm.get('useDateRange').value) {
            if (!this.validateDateRange()) {
                Notifier.Error(
                    this.notificationService,
                    this.container,
                    `Please select a date range.`,
                    'top',
                );
                return;
            }

            if (this.exportForm.get('transactionHistoryChecked').value) {
                this.transactionsClient
                    .downloadAllTransactionHistoriesFromRange(this.exportForm.get('from').value, this.exportForm.get('to').value)
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }

            if (this.exportForm.get('parkingHistoryChecked').value) {
                this.parkingEntriesClient
                    .downloadAllParkingEntriesFromRange(this.exportForm.get('from').value, this.exportForm.get('to').value)
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        } else {
            if (this.exportForm.get('transactionHistoryChecked').value) {
                this.transactionsClient
                    .downloadAllTransactionHistories()
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }

            if (this.exportForm.get('parkingHistoryChecked').value) {
                this.parkingEntriesClient
                    .downloadAllParkingEntries()
                    .subscribe(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('Download', response.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        }
    }

    validateDateRange() {
        return (
            this.exportForm.get("from").value &&
            this.exportForm.get("to").value
        )
    }

    validateExportHistorySelection() {
        return (
            this.exportForm.get("transactionHistoryChecked").value ||
            this.exportForm.get("parkingHistoryChecked").value
        )
    }

    navigateToParker(parkerId: number) {
        this.router.navigate(["/portal", parkerId]);
    }

    closeRegisterParkerDialog() {
        this.openGeneralForm = false;
        this.spinnerService.sendMessage(false);
    }
}
