import { HostNames } from "../app-route.constants";

export class HostNameHelper {
    static IsForAdmin() {
        var host = window.location.hostname.split(".")[0].toLowerCase();
        return host.includes(HostNames.ParkingAdmin)
    }

    static IsForParker() {
        var host = window.location.hostname.split(".")[0].toLowerCase();
        return host.includes(HostNames.Parking);
    }
}