export const AdminActions = {
    Administration: 'administration',
    Portal: 'portal'
};

interface AppRoutePathsType {
    readonly Connect: string;
    readonly Manage: string;
    readonly Aministration: string;
    readonly ParkerHome: string;
    readonly Portal: string;
}

const appRoutes: AppRoutePathsType = {
    Connect: 'connect',
    ParkerHome: 'home',
    Manage: 'manage',
    Aministration: 'administration',
    Portal: "portal"
}

export const HostNames = {
    Parking: 'parking',
    ParkingAdmin: 'parkingadmin'
}

export const AppRoutes: AppRoutePathsType = appRoutes;