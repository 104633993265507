
<div *ngIf="loading" class="k-i-loading"></div>
<app-parker-detail-dialog
  [model]="generalCommand"
  [isOpen]="openGeneralForm"
  (save)="saveGeneralFormHandler($event)"
  (cancel)="cancelGeneralFormHandler()"
>
</app-parker-detail-dialog>
<div class="form-group row" #appendContainer></div>
<div class="form-group row" *ngIf="parker">
  <div class="col-4 col-md-3">
    <div class="form-group row">
      <div class="col-6">
        <button
          style="width: 100%"
          kendoButton
          [primary]="true"
          (click)="createParkingEventClick()"
        >
          Create Parking Event
        </button>
      </div>
      <div class="col-6">
        <button
          style="width: 100%"
          kendoButton
          [primary]="true"
          (click)="createPaymentRequestClick()"
        >
          Send Payment Request
        </button>
      </div>
    </div>
    <div
      class="form-group row"
      *ngIf="this.parker && this.parker.acceptTermsRequired"
    >
      <mat-card class="col-12">
        <div class="alert alert-warning" role="alert">
          This parker has not accepted Parking Guidelines
        </div>
      </mat-card>
    </div>
    <div class="form-group row" *ngIf="this.parker">
      <mat-card class="col-12">
        <mat-card-content>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Card ID</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.cardId"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Name</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.firstName + ' ' + parker?.lastName"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Email</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.email"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Company</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.employer"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Phone</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.mobile"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Category</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.parkingCategory?.name"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Type</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.parkerType?.name"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Payment Method</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.paymentMethod?.name"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"
              >Last T & C Accepted On</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                [value]="parker?.latestAcceptTermsOn | date: 'medium'"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Balance (AUD)</label>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                [value]="parker?.currentBalance | currency: 'AUD'"
                readonly
              />
            </div>
            <button
              style="margin-left: 5px"
              kendoButton
              [primary]="true"
              (click)="adjustBalanceClick()"
            >
              Adjust balance
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="col-6 col-md-9" style="padding-left: 15px">
    <div class="form-group row">
      <div class="col-12">
        <button
          style="width: 100%"
          kendoButton
          [primary]="true"
          (click)="reverseParkingEntryClick()"
        >
          Reverse Parking Entry
        </button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-6" style="padding-right: 0px">
        <div *ngIf="parkingEntryGridService.loading" class="k-i-loading"></div>
        <kendo-grid
          [data]="entryView | async"
          [rowClass]="isDisabled"
          [pageSize]="parkingEntrystate.take"
          [skip]="parkingEntrystate.skip"
          [sort]="parkingEntrystate.sort"
          [filter]="parkingEntrystate.filter"
          filterable="'menu'"
          [group]="parkingEntrystate.group"
          [groupable]="false"
          [sortable]="true"
          [pageable]="true"
          [scrollable]="'scrollable'"
          (dataStateChange)="entryDataStateChange($event)"
          [height]="600"
          kendoGridSelectBy="id"
          [selectedKeys]="entrySelection"
          [selectable]="selectableSettings"
        >
          <!-- <ng-template
            kendoPagerTemplate
            let-totalPages="totalPages"
            let-currentPage="currentPage"
          >
            <kendo-pager-page-sizes
              [pageSizes]="pagesizes"
            ></kendo-pager-page-sizes>
          </ng-template> -->
          <ng-template kendoGridToolbarTemplate [position]="'top'">
            <span class="k-card-header"> Parking History </span>
          </ng-template>
          <kendo-grid-checkbox-column
            [width]="40"
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            showSelectAll="true"
          ></kendo-grid-checkbox-column>

          <kendo-grid-column
            field="dateTimeEntry"
            title="Date Entry"
            filter="date"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{
                dataItem.dateTimeEntry | date: "yyyy-dd-MM HH:mm:ss"
              }}</span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
      <div class="col-6" style="padding-right: 0px">
        <div *ngIf="transactionGridService.loading" class="k-i-loading"></div>
        <kendo-grid
          [data]="transactionView | async"
          [pageSize]="transactionEntrystate.take"
          [skip]="transactionEntrystate.skip"
          [sort]="transactionEntrystate.sort"
          [filter]="transactionEntrystate.filter"
          filterable="'menu'"
          [group]="transactionEntrystate.group"
          [groupable]="false"
          [sortable]="true"
          [pageable]="true"
          [scrollable]="'scrollable'"
          (dataStateChange)="transactionDataStateChange($event)"
          [height]="600"
        >
          <!-- <ng-template
            kendoPagerTemplate
            let-totalPages="totalPages"
            let-currentPage="currentPage"
          >
            <kendo-pager-page-sizes
              [pageSizes]="pagesizes"
            ></kendo-pager-page-sizes>
          </ng-template> -->
          <ng-template kendoGridToolbarTemplate [position]="'top'">
            <span class="k-card-header"> Transaction History </span>
          </ng-template>
          <kendo-grid-column
            field="createdOn"
            title="Date"
            filter="date"
            [width]="60"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{
                dataItem.createdOn | date: "yyyy-dd-MM HH:mm:ss"
              }}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="transactionType" title="Event" [width]="80">
          </kendo-grid-column>
          <kendo-grid-column
            [width]="50"
            field="indicativeAmount"
            title="Amount"
            filter="numeric"
            [style]="{'text-align': 'right'}"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span
                [ngClass]="{
                  'text-danger': dataItem.indicativeAmount < 0,
                  'text-success': dataItem.indicativeAmount > 0
                }"
                >{{ dataItem.indicativeAmount | currency: "AUD" }}</span
              >
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
