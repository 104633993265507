import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SpinnerService {
    private messageSource: BehaviorSubject<boolean> = new BehaviorSubject(
        null
    );

    currentMessage = this.messageSource.asObservable();

    constructor() { }

    sendMessage(message: boolean) {
        this.messageSource.next(message)
    }
}