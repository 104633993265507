import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { ParkerDetailComponent } from './parker-detail/parker-detail.component';
import { MainComponent } from './main/main.component';
import { AdministrationComponent } from './administration/administration.component';
import { ParkerDetailDialogComponent } from './parker-detail-dialog/parker-detail-dialog.component'

@NgModule({
  declarations: [ParkerDetailComponent, MainComponent, AdministrationComponent, ParkerDetailDialogComponent],
  imports: [
    CommonModule,
    SharedModuleModule,
    //RouterModule.forChild(routes)
  ],
  exports: []
})
export class AdminsModule { }
