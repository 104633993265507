import { CurrencyPipe } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    DataStateChangeEvent,
    SelectableSettings,
} from "@progress/kendo-angular-grid";
import { NotificationService } from "@progress/kendo-angular-notification";
import { State } from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PageHeaderService } from "src/api-authorization/page-header.service";
import { ParkingEntryGridService } from "src/api-authorization/parkingentry-grid.service";
import { SpinnerService } from "src/api-authorization/spinner.service";
import { TransactionGridService } from "src/api-authorization/transaction-grid.service";
import { Notifier } from "src/app/shared-module/notifier";
import {
    AdjustBalanceCommand,
    EnterCarParkCommand,
    Parker,
    ParkersClient,
    ReverseParkingEntryCommand,
    SendPaymentRequestCommand,
    TransactionHistory,
} from "src/app/web-api-client";
import { DialogTypes } from "../dialog-type.constants";
import { IParkerDetailDialog } from "../parker-detail-dialog/parker-detail-dialog.model";

@Component({
    selector: "app-parker-detail",
    templateUrl: "./parker-detail.component.html",
    styleUrls: ["./parker-detail.component.css"],
    providers: [NotificationService],
})
export class ParkerDetailComponent implements OnInit {
    @ViewChild("appendContainer", { read: ViewContainerRef })
    public container: ViewContainerRef;
    parker: Parker;
    parkerId: number;
    generalCommand: IParkerDetailDialog;
    openGeneralForm = false;
    notifyDialogMessage: string;

    entryView: Observable<any>;
    transactionView: Observable<any>;
    loading = false;

    parkingEntrystate: State = {
        skip: 0,
        take: 20,
        filter: { filters: [], logic: "or" },
        group: [],
        sort: [],
    };
    pagesizes = [5, 10, 15, 20, 25, 30];

    transactionEntrystate: State = {
        skip: 0,
        take: 20,
        filter: { filters: [], logic: "or" },
        group: [],
        sort: [],
    };

    entrySelection: number[] = [];
    selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: "single",
        drag: false,
    };

    constructor(
        private route: ActivatedRoute,
        private pageService: PageHeaderService,
        private router: Router,
        private parkersClient: ParkersClient,
        public transactionGridService: TransactionGridService,
        public parkingEntryGridService: ParkingEntryGridService,
        private notificationService: NotificationService,
        private spinnerService: SpinnerService,
        private cp: CurrencyPipe
    ) { }

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get("id");
        this.parkerId = id;
        this.loading = true;
        this.parkersClient.get(id).subscribe(
            (result) => {
                this.parker = result;
                this.pageService.sendMessage({ name: `Parker: ${result.fullName}` });
                this.loading = false;
            },
            (error) => {
                console.error(error);
                this.router.navigate(["/portal"]);
                this.loading = false;
            }
        );

        this.entryView = this.parkingEntryGridService.entries;
        this.transactionView = this.transactionGridService.transactions;
        this.parkingEntryGridService.read(this.parkerId);
        this.transactionGridService.read(this.parkerId);
    }

    entryDataStateChange(state: DataStateChangeEvent): void {
        this.parkingEntryGridService.state = state;
        this.parkingEntrystate = state;
        this.parkingEntryGridService.read(this.parkerId);
    }

    transactionDataStateChange(state: DataStateChangeEvent): void {
        this.transactionGridService.state = state;
        this.transactionEntrystate = state;
        this.transactionGridService.read(this.parkerId);
    }

    isDisabled(args) {
        return {
            "k-state-disabled": args.dataItem.reversed === true,
        };
    }

    createParkingEventClick() {
        this.openGeneralForm = true;
        const command: IParkerDetailDialog = {
            comment: "",
            parkerId: this.parker.id,
            type: DialogTypes.CreateParkingEvent,
            dateTimeEntry: new Date(),
        };

        this.generalCommand = command;
    }

    createPaymentRequestClick() {
        this.openGeneralForm = true;
        const command: IParkerDetailDialog = {
            comment: "",
            parkerId: this.parker.id,
            type: DialogTypes.SendPaymentRequest,
        };

        this.generalCommand = command;
    }

    adjustBalanceClick() {
        this.openGeneralForm = true;
        const command: IParkerDetailDialog = {
            comment: "",
            parkerId: this.parker.id,
            type: DialogTypes.AdjustBalance,
            amount: 0,
        };

        this.generalCommand = command;
    }

    reverseParkingEntryClick() {
        if (this.entrySelection.length < 1) {
            Notifier.Error(
                this.notificationService,
                this.container,
                `Please select a parking entry.`,
                "top"
            );
            return;
        }

        this.openGeneralForm = true;
        const command: IParkerDetailDialog = {
            comment: "",
            parkerId: this.parker.id,
            type: DialogTypes.ReverseParkingEntry,
            entrySelection: this.entrySelection,
        };

        this.generalCommand = command;
    }

    cancelGeneralFormHandler() {
        this.openGeneralForm = false;
        this.generalCommand = null;
        this.notifyDialogMessage = null;
    }

    saveGeneralFormHandler(command: IParkerDetailDialog) {
        this.spinnerService.sendMessage(true);
        const type = command.type;
        switch (type) {
            case DialogTypes.CreateParkingEvent:
                this.createParkingEvent(command);
                break;
            case DialogTypes.SendPaymentRequest:
                this.sendPaymentRequest(command);
                break;
            case DialogTypes.ReverseParkingEntry:
                this.reverseParkingEntry(command);
                break;
            case DialogTypes.AdjustBalance:
                this.adjustBalance(command);
                break;
            case null || undefined:
                throw new Error("Should not open dialog.");
        }
    }

    private createParkingEvent(command: IParkerDetailDialog) {
        const manualEnter = new EnterCarParkCommand({
            cardId: this.parker.cardId,
            email: this.parker.email,
            firstname: this.parker.firstName,
            lastname: this.parker.lastName,
            mobile: this.parker.mobile,
            employer: this.parker.employer,
            dateTimeEntry: command.dateTimeEntry,
            parkingCategory: command.category.name,
            parkerType: command.parkerType.name,
            isManualEntry: true,
            comment: command.comment,
        });

        this.parkersClient
            .enterCarpark(EnterCarParkCommand.fromJS(manualEnter))
            .pipe(switchMap((result) => this.parkersClient.get(this.parker.id)))
            .subscribe(
                (parker) => {
                    this.parkingEntryGridService.read(this.parkerId);
                    this.transactionGridService.read(this.parkerId);
                    this.parker = parker;
                    this.closeDialog();
                    Notifier.Success(
                        this.notificationService,
                        this.container,
                        `Parking entry has been created. Parker's new balance: ${this.cp.transform(
                            this.parker.currentBalance,
                            "AUD",
                            "symbol",
                            "1.2-2"
                        )}`,
                        "top"
                        //window.innerWidth
                    );
                },
                (error) => {
                    console.error(error);
                    this.closeDialog();
                    Notifier.Error(
                        this.notificationService,
                        this.container,
                        `Failed to manually create parking event.`,
                        "top"
                    );
                }
            );
    }

    private sendPaymentRequest(command: IParkerDetailDialog) {
        this.parkersClient
            .sendPaymentRequest(
                SendPaymentRequestCommand.fromJS(
                    new SendPaymentRequestCommand({
                        parkerId: command.parkerId,
                        comment: command.comment,
                    })
                )
            )
            .subscribe(
                () => {
                    this.closeDialog();
                    Notifier.Success(
                        this.notificationService,
                        this.container,
                        `An email has been sent to ${this.parker.fullName}`,
                        "top"
                        //window.innerWidth
                    );
                },
                (error) => {
                    console.error(error);
                    this.closeDialog();
                    Notifier.Error(
                        this.notificationService,
                        this.container,
                        `Failed send payment request.`,
                        "top"
                        //window.innerWidth
                    );
                }
            );
    }

    private reverseParkingEntry(command: IParkerDetailDialog) {
        this.parkersClient
            .reverseParkingEntry(
                ReverseParkingEntryCommand.fromJS(
                    new ReverseParkingEntryCommand({
                        parkerId: command.parkerId,
                        comment: command.comment,
                        parkingEntryId: this.entrySelection[0],
                    })
                )
            )
            .subscribe(
                (newBalance) => {
                    this.parkingEntryGridService.read(this.parkerId);
                    this.transactionGridService.read(this.parkerId);
                    this.entrySelection = [];
                    this.parker.currentBalance = newBalance;
                    this.closeDialog();
                    Notifier.Success(
                        this.notificationService,
                        this.container,
                        `Parking entry has been reversed. Parker's new balance: ${this.cp.transform(
                            newBalance,
                            "AUD",
                            "symbol",
                            "1.2-2"
                        )}`,
                        "top"
                        //window.innerWidth
                    );
                },
                (error) => {
                    console.error(error);
                    this.closeDialog();
                    Notifier.Error(
                        this.notificationService,
                        this.container,
                        `Failed to reverse this parking entry.`,
                        "top"
                        //window.innerWidth
                    );
                }
            );
    }

    private adjustBalance(command: IParkerDetailDialog) {
        this.parkersClient
            .adjustBalance(
                AdjustBalanceCommand.fromJS(
                    new AdjustBalanceCommand({
                        parkerId: this.parker.id,
                        adjustingValue: command.amount,
                        comment: command.comment,
                    })
                )
            )
            .subscribe(
                (newBalance) => {
                    this.transactionGridService.read(this.parkerId);
                    this.entrySelection = [];
                    this.parker.currentBalance = newBalance;
                    this.closeDialog();
                    Notifier.Success(
                        this.notificationService,
                        this.container,
                        `Parker's balance has been adjusted with new balance: ${this.cp.transform(
                            newBalance,
                            "AUD",
                            "symbol",
                            "1.2-2"
                        )}`,
                        "top"
                        //window.innerWidth
                    );
                },
                (error) => {
                    console.error(error);
                    this.closeDialog();
                    Notifier.Error(
                        this.notificationService,
                        this.container,
                        `Failed to adjust parker's account balance.`,
                        "top"
                        //window.innerWidth
                    );
                }
            );
    }

    closeDialog() {
        this.openGeneralForm = false;
        this.spinnerService.sendMessage(false);
    }
}
