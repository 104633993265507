import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TermsAndConditionsContent } from "src/app/web-api-client";

@Component({
    selector: "app-terms",
    templateUrl: "./terms.component.html",
    styleUrls: ["./terms.component.css"],
})
export class TermsComponent implements OnInit {
    @Input()
    forAdmin = false;
    @Input()
    openTerms = false;
    // If for admin content, this should be content for admin
    @Input() public set termsContent(content: TermsAndConditionsContent) {
        this.editorForm.patchValue({ id: content.id, description: content.description });
    }
    @Input() public set liveTermsContent(content: TermsAndConditionsContent) {
        this.liveTc = content;
    }

    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<TermsAndConditionsContent> = new EventEmitter();
    @Output() accept: EventEmitter<any> = new EventEmitter();
    @Output() notify: EventEmitter<any> = new EventEmitter();

    editorForm: FormGroup = new FormGroup({
        description: new FormControl(''),
        id: new FormControl('')
    });
    liveTc: TermsAndConditionsContent;

    constructor() { }

    ngOnInit(): void { }

    onSave(e): void {
        e.preventDefault();
        this.save.emit(this.editorForm.value);
    }

    onNotify(e): void {
        e.preventDefault();
        this.notify.emit(this.editorForm.value);
    }

    onAccept(e): void {
        e.preventDefault();
        this.accept.emit(this.editorForm.value);
    }

    onCancel(e): void {
        e.preventDefault();
        this.closeForm();
    }

    closeForm(): void {
        this.cancel.emit();
    }

    public get height() {
        return window.innerHeight;
    }

    public get width() {
        return window.innerWidth;
    }
}
