<kendo-dialog *ngIf="isOpen" [width]="700" [height]="600" (close)="closeForm()">
  <kendo-dialog-titlebar>
    {{ generalForm.get("type").value }}
  </kendo-dialog-titlebar>

  <form novalidate class="k-form" [formGroup]="generalForm">
    <div class="form-group row" *ngIf="AdjustBalance">
      <label class="col-sm-2 col-form-label">Adjust Balance</label>
      <div class="col-sm-10">
        <kendo-textbox-container
          floatingLabel="New Balance"
          class="k-textbox-container"
          style="width: 100%"
        >
          <kendo-numerictextbox
            class="form-control"
            [format]="'c2'"
            formControlName="amount"
            [ngClass]="{
              'ng-invalid ng-touched': submitted && f.amount.errors
            }"
            required
          >
          </kendo-numerictextbox>
          <div
            *ngIf="submitted && f.amount.errors"
            class="k-tooltip-validation"
          >
            <div *ngIf="f.title.errors.required">Amount is required</div>
          </div>
        </kendo-textbox-container>
      </div>
    </div>
    <div class="form-group row" *ngIf="RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label"
        >Security ID</label
      >
      <div class="col-sm-10">
        <kendo-textbox
          formControlName="cardId"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.cardId.errors
          }"
        ></kendo-textbox>
        <div *ngIf="submitted && f.cardId.errors" class="k-tooltip-validation">
          <div *ngIf="f.cardId.errors.required">Security Id is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label"
        >First Name</label
      >
      <div class="col-sm-10">
        <kendo-textbox
          formControlName="firstName"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.firstName.errors
          }"
        ></kendo-textbox>
        <div
          *ngIf="submitted && f.firstName.errors"
          class="k-tooltip-validation"
        >
          <div *ngIf="f.firstName.errors.required">Firstname is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label"
        >Last Name</label
      >
      <div class="col-sm-10">
        <kendo-textbox
          formControlName="lastName"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.lastName.errors
          }"
        ></kendo-textbox>
        <div
          *ngIf="submitted && f.lastName.errors"
          class="k-tooltip-validation"
        >
          <div *ngIf="f.lastName.errors.required">Last name is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label">Email</label>
      <div class="col-sm-10">
        <kendo-textbox
          formControlName="email"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.email.errors
          }"
        ></kendo-textbox>
        <div *ngIf="submitted && f.email.errors" class="k-tooltip-validation">
          <div *ngIf="f.email.errors.required">Email is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label">Company</label>
      <div class="col-sm-10">
        <kendo-textbox
          formControlName="employer"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.employer.errors
          }"
        ></kendo-textbox>
        <div
          *ngIf="submitted && f.employer.errors"
          class="k-tooltip-validation"
        >
          <div *ngIf="f.employer.errors.required">Company is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label">Phone</label>
      <div class="col-sm-10">
        <kendo-textbox
          formControlName="mobile"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.mobile.errors
          }"
        ></kendo-textbox>
        <div *ngIf="submitted && f.mobile.errors" class="k-tooltip-validation">
          <div *ngIf="f.mobile.errors.required">Phone is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="CreateParkingEvent">
      <label for="inputPassword" class="col-sm-2 col-form-label"
        >Date Time Entry</label
      >
      <div class="col-sm-10">
        <kendo-datetimepicker
          formControlName="dateTimeEntry"
          [format]="'dd-MMM-yyyy HH:mm:ss'"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.dateTimeEntry.errors
          }"
        >
        </kendo-datetimepicker>
        <div
          *ngIf="submitted && f.dateTimeEntry.errors"
          class="k-tooltip-validation"
        >
          <div *ngIf="f.dateTimeEntry.errors.required">
            Date entry is required
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="CreateParkingEvent || RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label"
        >Category</label
      >
      <div class="col-sm-10">
        <kendo-dropdownlist
          formControlName="category"
          [data]="categories | async"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="false"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.category.errors
          }"
        >
        </kendo-dropdownlist>
        <div
          *ngIf="submitted && f.category.errors"
          class="k-tooltip-validation"
        >
          <div *ngIf="f.category.errors.required">Category is required</div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="CreateParkingEvent || RegisterNewParker">
      <label for="inputPassword" class="col-sm-2 col-form-label">Type</label>
      <div class="col-sm-10">
        <kendo-dropdownlist
          formControlName="parkerType"
          [data]="parkerTypes | async"
          [textField]="'name'"
          [valueField]="'id'"
          [valuePrimitive]="false"
          required
          [ngClass]="{
            'ng-invalid ng-touched': submitted && f.parkerType.errors
          }"
        >
        </kendo-dropdownlist>
        <div
          *ngIf="submitted && f.parkerType.errors"
          class="k-tooltip-validation"
        >
          <div *ngIf="f.parkerType.errors.required">
            Parker type is required
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Comment</label>
      <div class="col-sm-10">
        <kendo-textbox formControlName="comment" required></kendo-textbox>
      </div>
    </div>
  </form>

  <kendo-dialog-actions>
    <button class="k-button" (click)="onCancel($event)">Close</button>
    <!-- <button [disabled]="!generalForm.valid" class="k-button k-primary" (click)="onSave($event)">
            Save
        </button> -->
    <button class="k-button k-primary" (click)="onSave($event)">Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
