<body>
  <app-nav-menu></app-nav-menu>
  <div
    [ngClass]="{
      'container-fluid': isAdmin,
      'container': !isAdmin
    }"
  >
    <!-- <app-spinner></app-spinner> -->
    <div style="padding-top: 120px">
      <router-outlet></router-outlet>
    </div>
    
  </div>
  <footer class="footer border-top text-muted" [style.bottom]="isAdmin ? 0 : 'unset' ">
    <div
      [ngClass]="{
        'container-fluid': isAdmin,
        'container': !isAdmin
      }"
    >
      <span class="text-muted"
        >© {{ currentDate.getFullYear() }} - Hunter Medical Research
        Institute</span
      >
    </div>
  </footer>
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
</body>
