<!-- Terms and conditions -->
<div *ngIf="dataService.loading" class="k-i-loading"></div>
<app-terms [termsContent]="termsContent" [liveTermsContent]="termsContentLive" [forAdmin]="true" [openTerms]="openTerms"
    (save)="saveDraftTermsHandler($event)" (notify)="notifyParkerHandler($event)" (cancel)="cancelTermsHandler()">
</app-terms>
<app-parker-detail-dialog [model]="generalCommand" [isOpen]="openGeneralForm" (save)="saveGeneralFormHandler($event)"
    (cancel)="cancelGeneralFormHandler()">
</app-parker-detail-dialog>
<form novalidate [formGroup]="exportForm" #appendContainer>
    <div class="form-group row">
        <div class="col-12 col-md-2">
            <kendo-textbox-container floatingLabel="From" class="k-textbox-container" style="width: 100%">
                <kendo-datepicker formControlName="from" required [format]="'dd-MMM-yyyy'">
                </kendo-datepicker>
            </kendo-textbox-container>
        </div>
        <div class="col-12 col-md-2">
            <kendo-textbox-container floatingLabel="To" class="k-textbox-container" style="width: 100%">
                <kendo-datepicker formControlName="to" required [format]="'dd-MMM-yyyy'">
                </kendo-datepicker>
            </kendo-textbox-container>
        </div>
        <div class="col-12 col-md-2">
            <kendo-textbox-container floatingLabel="Use This Date Range" class="k-textbox-container"
                style="width: 100%">
                <input style="margin-top: 15px" type="checkbox" formControlName="useDateRange" kendoCheckBox />
            </kendo-textbox-container>
        </div>
        <div class="col-12 col-md-2">
            <kendo-textbox-container floatingLabel="Traction History" class="k-textbox-container" style="width: 100%">
                <input style="margin-top: 15px" type="checkbox" formControlName="transactionHistoryChecked"
                    kendoCheckBox />
            </kendo-textbox-container>
        </div>
        <div class="col-12 col-md-2">
            <kendo-textbox-container floatingLabel="Parking History" class="k-textbox-container" style="width: 100%">
                <input style="margin-top: 15px" type="checkbox" formControlName="parkingHistoryChecked" kendoCheckBox />
            </kendo-textbox-container>
        </div>
    </div>
</form>
<div class="form-group row">
    <!-- Toolbar -->
    <kendo-toolbar [overflow]="true" [style.width.%]="100">
        <kendo-toolbar-buttongroup>
            <kendo-toolbar-button [icon]="'notification'" [primary]="true" (click)="sendReminder()"
                [text]="'Send Reminder to Selected'">
            </kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-separator></kendo-toolbar-separator>
        <kendo-toolbar-buttongroup>
            <kendo-toolbar-button [icon]="'file-csv'" [primary]="true" (click)="exportSelectedParkers()"
                [text]="'Export Selected'">
            </kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-separator></kendo-toolbar-separator>
        <kendo-toolbar-buttongroup>
            <kendo-toolbar-button [icon]="'download'" [primary]="true" (click)="exportAllParkers()"
                [text]="'Export All'">
            </kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-separator></kendo-toolbar-separator>
        <kendo-toolbar-buttongroup>
            <kendo-toolbar-button [icon]="'plus-circle'" [primary]="true" (click)="registerNewParkerClick()"
                [text]="'Register New Parker'">
            </kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-separator></kendo-toolbar-separator>
        <kendo-toolbar-buttongroup>
            <kendo-toolbar-button [icon]="'page-properties'" [primary]="true" (click)="openTermsContent()"
                [text]="'Parking Guidelines'">
            </kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
    </kendo-toolbar>
</div>
<div class="form-group row">
    <kendo-grid [data]="view | async" [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort"
        [filter]="state.filter" filterable="'menu'" [group]="state.group" [groupable]="false" [sortable]="true"
        [pageable]="true" [scrollable]="'scrollable'" (dataStateChange)="dataStateChange($event)" [height]="600"
        kendoGridSelectBy="id" [selectedKeys]="parkersSelection">
        <kendo-grid-checkbox-column [width]="45" [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }" [resizable]="false" [columnMenu]="false" showSelectAll="true">
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="fullName" title="Full Name" [editable]="false"></kendo-grid-column>
        <kendo-grid-column field="email" title="Email"></kendo-grid-column>
        <kendo-grid-column field="currentBalance" title="Balance" filter="numeric" [style]="{'text-align': 'right'}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{
            'text-danger': dataItem.currentBalance < 0,
            'text-success': dataItem.currentBalance > 0
          }">{{ dataItem.currentBalance | currency: "AUD" }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="lastParkingOn" title="Last Entry" filter="date">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.lastParkingOn | date: "yyyy-dd-MM HH:mm:ss" }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="parkingCategory" title="Category" filter="text" [filterable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.parkingCategory.name }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="parkerType" title="Parker Type" filter="text" [filterable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.parkerType.name }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="paymentMethod" title="Payment Method" filter="text" [filterable]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.paymentMethod?.name }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-command-column title="" width="220">
            <ng-template kendoGridCellTemplate let-dataItem>
                <button kendoButton [primary]="true" (click)="navigateToParker(dataItem.id)">
                    View
                </button>
            </ng-template>
        </kendo-grid-command-column>
    </kendo-grid>
</div>
