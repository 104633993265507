import { NotificationService } from "@progress/kendo-angular-notification";
import { ViewContainerRef } from "@angular/core";

export class Notifier {
  static Warning(
    notificationService: NotificationService,
    container: ViewContainerRef,
    message: string, width?: number
  ) {
    notificationService.show({
      content: message,
      appendTo: container,
      hideAfter: 6000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "warning", icon: true },
      width: width
    });
  }

  static Info(
    notificationService: NotificationService,
    container: ViewContainerRef,
    message: string, width?: number
  ) {
    notificationService.show({
      content: message,
      appendTo: container,
      hideAfter: 6000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "info", icon: true },
      width: width
    });
  }

  static Success(
    notificationService: NotificationService,
    container: ViewContainerRef,
    message: string, vertical: 'top' | 'bottom', width?: number, 
  ) {
    notificationService.show({
      content: message,
      appendTo: container,
      hideAfter: 5000,
      position: { horizontal: "center", vertical: vertical },
      animation: { type: "fade", duration: 400 },
      type: { style: "success", icon: true },
      width: width
    });
  }

  static Error(
    notificationService: NotificationService,
    container: ViewContainerRef,
    message: string, vertical: 'top' | 'bottom', width?: number
  ) {
    notificationService.show({
      content: message,
      appendTo: container,
      hideAfter: 5000,
      position: { horizontal: "center", vertical: vertical },
      animation: { type: "fade", duration: 400 },
      type: { style: "error", icon: true },
      width: width
    });
  }
}
