import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { IHeader, PageHeaderService } from 'src/api-authorization/page-header.service';
import { HostNameHelper } from '../shared-module/hostname.helper';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  pageHeaderName: Observable<string>;
  /**
   *
   */
  constructor(private pageService: PageHeaderService) {
    this.pageHeaderName = this.pageService.currentMessage;    
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  get isAdmin() {
    return HostNameHelper.IsForAdmin();
  }
}
