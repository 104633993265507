import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { PageHeaderService } from "src/api-authorization/page-header.service";
import {
    AcceptTermsCommand,
    CaptureDepositOrderCommand,
    CreateDepositOrderCommand,
    Parker,
    ParkersClient,
    ParkingEntriesClient,
    PaymentsClient,
    TermsAndConditionsContent,
    TermsConditionsClient,
    TransactionHistoriesClient,
} from "../web-api-client";
import { IPayPalConfig } from "ngx-paypal";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthorizeService } from "src/api-authorization/authorize.service";
import { map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NotificationService } from "@progress/kendo-angular-notification";
import { Notifier } from "../shared-module/notifier";
import { SpinnerService } from "src/api-authorization/spinner.service";
import { CurrencyPipe } from "@angular/common";

@Component({
    selector: "app-parkers",
    templateUrl: "./parkers.component.html",
    styleUrls: ["./parkers.component.css"],
})
export class ParkersComponent implements OnInit {
    @ViewChild('appendContainer', { read: ViewContainerRef }) public container: ViewContainerRef;
    parker: Parker;
    payPalConfig?: IPayPalConfig;
    termsContent: TermsAndConditionsContent = new TermsAndConditionsContent();
    openTerms: boolean = false;

    depositForm: FormGroup = new FormGroup({
        amount: new FormControl("", Validators.required),
        parkerCardId: new FormControl("", Validators.required),
    });

    constructor(
        private pageService: PageHeaderService,
        private parkersClient: ParkersClient,
        private transactionsClient: TransactionHistoriesClient,
        private parkingEntriesClient: ParkingEntriesClient,
        private termsClient: TermsConditionsClient,
        private authorizeService: AuthorizeService,
        private paymentsClient: PaymentsClient,
        private notificationService: NotificationService,
        private spinnerService: SpinnerService,
        private cp: CurrencyPipe
    ) {
        this.pageService.sendMessage({ name: "HMRI Parking: My Account" });
    }

    resetDispositForm(): void {
        this.depositForm.reset({ amount: 10, parkerCardId: this.parker.cardId });
    }

    ngOnInit(): void {
        this.spinnerService.sendMessage(true);
        this.termsClient.getTerms().subscribe(
            (result) => this.termsContent = result? result: this.termsContent,
            (error) => console.error(error)
        );

        this.authorizeService
            .getUser()
            .pipe(map((u) => u && u.cardId))
            .pipe(switchMap((cardId) => this.parkersClient.getByCardId(cardId)))
            .subscribe(
                (result) => {
                    this.parker = result;
                    this.resetDispositForm();
                    this.spinnerService.sendMessage(false);
                },
                (error) => {
                    console.error(error);
                    this.spinnerService.sendMessage(false);
                }
            );
        this.initServerConfig();
        //this.initClientConfig();
    }

    openTermsContent() {
        this.openTerms = true;
    }

    cancelHandler() {
        this.openTerms = false;
    }

    acceptHandler() {
        if (this.parker) {
            this.openTerms = false;
            this.parkersClient
                .acceptTerms(
                    AcceptTermsCommand.fromJS({ parkerCardId: this.parker.cardId })
                )
                .subscribe(
                    () => {
                        console.log("Success accepted");
                        this.parker.acceptTermsRequired = false;
                    },
                    (error) => console.log(error)
                );
        }
    }

    downloadParkingEntries() {
        this.parkingEntriesClient
            .downloadAllParkingEntriesFor(this.parker.id)
            .subscribe((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("Download", response.fileName);
                document.body.appendChild(link);
                link.click();
            });
    }

    downloadTransactionHistories() {
        this.transactionsClient
            .downloadAllTransactionHistoriesFor(this.parker.id)
            .subscribe((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("Download", response.fileName);
                document.body.appendChild(link);
                link.click();
            });
    }

    // If an order needs to be authorised, set intent to authorize
    // The order is created on server, make sure the intent is also set to authorise
    // This approach below does not authorise an order, it creates the order on server then captures it
    // Therefore the intent was set to capture
    private initServerConfig(): void {
        this.payPalConfig = {
            clientId: environment.paypalClientId,
            style: {
                color: "white",
                shape: "pill",
                label: "pay",
                layout: "vertical",
            },
            currency: "AUD",
            
            advanced: {
                commit: "true",
                extraQueryParams: [
                    {
                        name: "intent",
                        value: "capture",
                    },
                    {
                        name: "locale",
                        value: "en_AU",
                    },
                ],
            },
            

            // for creating orders (transactions) on server see
            // https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
            createOrderOnServer: (data) => {
                console.log("data", data);
                return this.paymentsClient
                    .createOrder(
                        CreateDepositOrderCommand.fromJS(
                            new CreateDepositOrderCommand({
                                amount: this.depositForm.get("amount").value,
                                parkerId: this.parker.id,
                            })
                        )
                    )
                    .toPromise()
                    .then((orderId) => orderId);
            },
            // authorizeOnServer: (approveData) => {
            //     console.log('approveData', approveData);
            //     this.spinnerService.sendMessage(true);
            //     return this.paymentsClient.authorizeOrder(
            //         AuthorizeDepositOrderCommand.fromJS(new AuthorizeDepositOrderCommand(
            //             { orderId: approveData.orderID, parkerId: this.parker.id }))).toPromise()
            //         .then((result) => {
            //             return result;
            //         }).then((result) => {
            //             //result.purchase_units[0].payments.authorizations[0].id;
            //             //alert('Authorization created for ' + result.payer.name);
            //             this.spinnerService.sendMessage(false);
            //         });
            // },
            onApprove: (data, actions) => {
                this.spinnerService.sendMessage(true);
                this.paymentsClient
                    .captureOrder(
                        CaptureDepositOrderCommand.fromJS(
                            new CaptureDepositOrderCommand({
                                parkerId: this.parker.id,
                                orderId: data.orderID,
                            })
                        )
                    )
                    .toPromise()
                    .then((newBalance) => {
                        this.parker.currentBalance = newBalance;
                        this.spinnerService.sendMessage(false);
                        Notifier.Success(
                            this.notificationService,
                            this.container,
                            `Successfully. Your new balance is: ${this.cp.transform(newBalance, "AUD", "symbol", "1.2-2")}`,
                            'top',
                        );
                    });
                // actions.order.get().then(details => {
                //     console.log('onApprove - you can get full order details inside onApprove: ', details);
                // });
            },
            onCancel: (data, actions) => {
                console.log("OnCancel", data, actions);
                //this.showCancel = true;
                this.spinnerService.sendMessage(false);
            },
            onError: (err) => {
                console.log("OnError", err);
                this.spinnerService.sendMessage(false);
                Notifier.Error(
                    this.notificationService,
                    this.container,
                    `Failed to deposit funds.`,
                    'top',
                );
                //this.showError = true;
            },
            onClick: (data, actions) => {
                console.log("onClick", data, actions);
            },
        };
    }
}
