<header>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" [ngClass]="isAdmin ? 'bg-light ' : 'bg-parker'">
    <div [ngClass]="{
        'container-fluid': isAdmin,
        container: !isAdmin
      }" style="padding-left: 0px; padding-right: 0px">
      <button mat-button [routerLink]="['/']" class="navbar-brand mat-button"
              style="cursor: pointer; background-color: white; padding: 2px">
        <div class="">
          <img src="assets/images/logo.png" width="80" height="70" alt="Home" style="background-color: white" />
        </div>

        <div class="mat-button-ripple mat-ripple" matripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
      <button mat-icon-button class="navbar-toggler mat-button" type="button" data-toggle="collapse"
              data-target="#navbarResponsive" aria-controls="navbarResponsive" [attr.aria-expanded]="isExpanded"
              aria-label="Toggle navigation" (click)="toggle()">
        <mat-icon style="color: white">menu</mat-icon>
        <div class="mat-button-ripple mat-ripple" matripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive" [ngClass]="{ show: isExpanded }">
        <div class="form-title-big" style="width: 100%">
          <div style="margin: 0 auto">
            <span style="text-align: center">{{ pageHeaderName | async }}</span>
          </div>
        </div>
        <app-login-menu></app-login-menu>
      </div>
    </div>
  </nav>
</header>
