import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

export interface IHeader {
    name?: string;
}

@Injectable({
    providedIn: "root",
})

export class PageHeaderService {
    private messageSource : BehaviorSubject<IHeader | null> = new BehaviorSubject(
        null
    );

  currentMessage = this.messageSource.asObservable().pipe(map(m => m?.name));

  constructor() { }

  sendMessage(message: IHeader) {
    this.messageSource.next(message)
  }
}