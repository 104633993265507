import { Component, OnInit } from "@angular/core";
import { filter, map } from "rxjs/operators";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { SpinnerService } from "src/api-authorization/spinner.service";
import { HostNames } from "./app-route.constants";
import { HostNameHelper } from "./shared-module/hostname.helper";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  currentDate: Date = new Date();
  
  loading: boolean = false;
  
  constructor(private router: Router, private titleService: Title, private spinnerService: SpinnerService) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe((event) => {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        ).join(" | ");
        this.titleService.setTitle(`${title} - HMRI Parking`);

        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (event instanceof NavigationEnd) {
          this.loading = false;
        } else if (event instanceof NavigationCancel) {
          this.loading = false;
        } else if (event instanceof NavigationError) {
          this.loading = false;
        }
      });

      this.spinnerService.currentMessage.subscribe(loading => this.loading = loading);
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  get isAdmin() {
    return HostNameHelper.IsForAdmin();
  }
}
