<kendo-dialog *ngIf="openTerms" [width]="width- 10" [height]="height - 150" (close)="closeForm()">
    <kendo-dialog-titlebar>
        Parking Guidelines
    </kendo-dialog-titlebar>
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'Draft Guidelines'" *ngIf="forAdmin">
            <ng-template kendoTabContent>
                <form novalidate class="k-form" [formGroup]="editorForm">
                    <div class="alert alert-info" role="alert">
                        <strong>Save Draft</strong> will save this new version of Parking Guidelines as draft.
                    </div>
                    <div class="alert alert-info" role="alert">
                        <strong>Set as Live and Requiring Acceptance</strong> will convert this new version of Parking Guidelinesto "Live" and mark all parkers as "Accept Terms Required" to accept this new version of Parking Guidelines.
                        The current version of Parking Guidelines will be retired. You may need to refesh this page to see the new Parking Guidelines.
                    </div>
                    <div class="form-group row">
                        <input type="hidden" formControlName="id" />
                        <kendo-editor formControlName="description" style="height: 800px; width: 100%;"
                            [readonly]="!forAdmin">
                            <kendo-toolbar [ngStyle]="{'display': forAdmin ? 'inherit' : 'none' }">
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-separator></kendo-toolbar-separator>
                                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                <!-- <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                            <kendo-toolbar-colorpicker kendoEditorBackColor></kendo-toolbar-colorpicker> -->
                                <kendo-toolbar-separator></kendo-toolbar-separator>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-separator></kendo-toolbar-separator>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <!-- <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup> -->
                            </kendo-toolbar>
                        </kendo-editor>
                    </div>
                </form>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Active Guidelines'" [selected]="true">
            <ng-template kendoTabContent>
                <kendo-editor [(ngModel)]="this.liveTc.description" [ngModelOptions]="{standalone: true}" style="height: 800px; width: 100%;"
                [readonly]="true">
                <kendo-toolbar [ngStyle]="{'display': forAdmin ? 'inherit' : 'none' }"></kendo-toolbar>
            </kendo-editor>
               
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>

    <kendo-dialog-actions>
        <button class="k-button" (click)="onCancel($event)">Close</button>
        <button *ngIf="forAdmin" class="k-button k-primary" (click)="onSave($event)">Save Draft</button>
        <button *ngIf="forAdmin && this.editorForm.get('id').value" class="k-button k-primary" (click)="onNotify($event)">Set as Live and Requiring
            Acceptance</button>
        <button *ngIf="!forAdmin" class="k-button k-primary" (click)="onAccept($event)">Accept</button>
    </kendo-dialog-actions>
</kendo-dialog>