import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { GridModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { EditorModule } from '@progress/kendo-angular-editor';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LayoutModule } from '@progress/kendo-angular-layout';

import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { TermsComponent } from './terms/terms.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [TermsComponent, SpinnerComponent, NotfoundComponent, LoadingSpinnerComponent],
  imports: [
    CommonModule,
    GridModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    ButtonsModule,
    DialogsModule,
    IndicatorsModule,
    NotificationModule,
    ToolBarModule,
    EditorModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    LayoutModule
  ],
  exports: [
    CommonModule,
    GridModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    ButtonsModule,
    DialogsModule,
    IndicatorsModule,
    NotificationModule,
    ToolBarModule,
    EditorModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    TermsComponent,
    SpinnerComponent,
    LoadingSpinnerComponent,
    TooltipModule,
    LayoutModule
  ],
})
export class SharedModuleModule {}
