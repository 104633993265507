import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ParkersComponent } from './parkers.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { NgxPayPalModule } from 'ngx-paypal';

@NgModule({
  declarations: [ParkersComponent],
  imports: [
    CommonModule,
    SharedModuleModule,
    NgxPayPalModule,
    //RouterModule.forChild(routes)
  ]
})
export class ParkersModule { }
