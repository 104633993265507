
<ul class="navbar-nav ml-auto" *ngIf="!(isAuthenticated | async)">
    <li class="nav-item">
        <a class="nav-link text-light" [routerLink]='["/authentication/login"]'>Connect</a>
    </li>
</ul>

<ul class="navbar-nav ml-auto" *ngIf="isAuthenticated | async">
    <!-- <li class="nav-item form-title-small" style="border-bottom: 2px solid;">
      <h5>Test title</h5>
    </li> -->
    <li class="nav-item text-light" >
      <button
        aria-haspopup="true"
        mat-button
        [matMenuTriggerFor]="menu"
        class="mat-button"
        style="padding: 0px;"
      >
      {{ userName | async }}
        <mat-icon>arrow_drop_down</mat-icon>
        <div class="mat-button-ripple mat-ripple" matripple=""></div>
        <div class="mat-button-focus-overlay"></div>
      </button>
      <mat-menu #menu="matMenu">
        <div>
          <a mat-menu-item [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">
            <mat-icon>exit_to_app</mat-icon>
            <span>Log out</span>
          </a>
        </div>
      </mat-menu>
    </li>
  </ul>