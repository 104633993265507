import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { NotificationService } from "@progress/kendo-angular-notification";
import { Observable } from "rxjs";
import { Notifier } from "src/app/shared-module/notifier";
import { ParkerType, ParkerTypesClient, ParkingCategoriesClient, ParkingCategory } from "src/app/web-api-client";
import { DialogTypes } from "../dialog-type.constants";
import { IParkerDetailDialog } from "./parker-detail-dialog.model";

@Component({
    selector: "app-parker-detail-dialog",
    templateUrl: "./parker-detail-dialog.component.html",
    styleUrls: ["./parker-detail-dialog.component.css"],
    providers: [NotificationService],
})
export class ParkerDetailDialogComponent implements OnInit {
    // @ViewChild("appendContainer", { static: true, read: ViewContainerRef })
    // container: ViewContainerRef;
    submitted = false;
    generalForm = this.fb.group({
        type: ["", Validators.required],
        firstName: [''],
        lastName: [''],
        mobile: [''],
        email: [''],
        employer: [''],
        cardId: [''],
        parkerId: ["", Validators.required],
        category: [{}],
        parkerType: [{}],
        dateTimeEntry: [new Date()],
        amount: [""],
        comment: ["", Validators.required],
        entrySelection: new FormArray([]),
    });
    @Input()
    isOpen = false;
    @Input() public set model(command: IParkerDetailDialog) {
        if(command != null) {
            this.generalForm.reset(command);
            this.setRequiredFields(command);
        } else {
            this.generalForm.reset();
        }
        this.submitted = false;
    }
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<IParkerDetailDialog> = new EventEmitter();

    categories: Observable<ParkingCategory[]>;
    parkerTypes: Observable<ParkerType[]>;

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private categoryClient: ParkingCategoriesClient,
        private parkerTypesClient: ParkerTypesClient
    ) { }

    ngOnInit(): void {
        this.categories = this.categoryClient.get();
        this.parkerTypes = this.parkerTypesClient.get();
    }

    onSave(e): void {
        this.submitted = true;
        e.preventDefault();
        if (this.generalForm.invalid) {
            return;
        }
        this.save.emit(this.generalForm.value);
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.generalForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                console.log(name);
                invalid.push(name);
            }
        }
        return invalid;
    }

    setRequiredFields(command: IParkerDetailDialog) {
        for (const key in this.generalForm.controls) {
            this.generalForm.get(key).clearValidators();
            this.generalForm.get(key).updateValueAndValidity();
        }
        this.generalForm.get('comment').setValidators(Validators.required);
        this.generalForm.get('comment').updateValueAndValidity();
        this.generalForm.get('parkerId').setValidators(Validators.required);
        this.generalForm.get('parkerId').updateValueAndValidity();
        const type = command.type;
        switch (type) {
            case DialogTypes.RegisterNewParker:
                this.generalForm.get('parkerId').clearValidators();
                this.generalForm.get('parkerId').updateValueAndValidity();
                this.generalForm.get('firstName').setValidators(Validators.required);
                this.generalForm.get('firstName').updateValueAndValidity();
                this.generalForm.get('lastName').setValidators(Validators.required);
                this.generalForm.get('lastName').updateValueAndValidity();
                this.generalForm.get('mobile').setValidators(Validators.required);
                this.generalForm.get('mobile').updateValueAndValidity();
                this.generalForm.get('email').setValidators([Validators.required, Validators.email]);
                this.generalForm.get('email').updateValueAndValidity();
                this.generalForm.get('employer').setValidators(Validators.required);
                this.generalForm.get('employer').updateValueAndValidity();
                this.generalForm.get('cardId').setValidators(Validators.required);
                this.generalForm.get('cardId').updateValueAndValidity();
                this.generalForm.get('category').setValidators(Validators.required);
                this.generalForm.get('category').updateValueAndValidity();
                this.generalForm.get('parkerType').setValidators(Validators.required);
                this.generalForm.get('parkerType').updateValueAndValidity();
                this.generalForm.get('dateTimeEntry').clearValidators();
                this.generalForm.get('dateTimeEntry').updateValueAndValidity();
                break;
            
            case DialogTypes.CreateParkingEvent:
                this.generalForm.get('dateTimeEntry').setValidators(Validators.required);
                this.generalForm.get('dateTimeEntry').updateValueAndValidity();
                this.generalForm.get('category').setValidators(Validators.required);
                this.generalForm.get('category').updateValueAndValidity();
                this.generalForm.get('parkerType').setValidators(Validators.required);
                this.generalForm.get('parkerType').updateValueAndValidity();
                break;
            case DialogTypes.SendPaymentRequest:
                break;
            case DialogTypes.ReverseParkingEntry:
                break;
            case DialogTypes.AdjustBalance:
                this.generalForm.get('amount').setValidators(Validators.required);
                this.generalForm.get('amount').updateValueAndValidity();
                break;
        }
    }

    get f() { return this.generalForm.controls; }

    get AdjustBalance(): boolean {
        return this.generalForm.get("type").value === DialogTypes.AdjustBalance;
    }

    get CreateParkingEvent(): boolean {
        return (
            this.generalForm.get("type").value === DialogTypes.CreateParkingEvent
        );
    }

    get ReverseParkingEntry(): boolean {
        return (
            this.generalForm.get("type").value === DialogTypes.ReverseParkingEntry
        );
    }

    get RegisterNewParker(): boolean {
        return (
            this.generalForm.get("type").value === DialogTypes.RegisterNewParker
        );
    }

    get SendPaymentRequest(): boolean {
        return (
            this.generalForm.get("type").value === DialogTypes.SendPaymentRequest
        );
    }

    onCancel(e): void {
        e.preventDefault();
        this.closeForm();
    }

    closeForm(): void {
        //this.generalForm.reset();
        this.submitted = false;
        this.cancel.emit();
    }
}
