import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { ApiAuthorizationModule } from "src/api-authorization/api-authorization.module";
import { AuthorizeGuard } from "src/api-authorization/authorize.guard";
import { AuthorizeInterceptor } from "src/api-authorization/authorize.interceptor";
import { SharedModuleModule } from "./shared-module/shared-module.module";
import { HostNames } from "./app-route.constants";
import { AdminsModule } from "./admins/admins.module";
import { ParkersModule } from "./parkers/parkers.module";
import { AdministrationComponent } from "./admins/administration/administration.component";
import { ParkersComponent } from "./parkers/parkers.component";
import { NotfoundComponent } from "./shared-module/notfound/notfound.component";
import { MainComponent } from "./admins/main/main.component";
import { ParkerDetailComponent } from "./admins/parker-detail/parker-detail.component";
import { CurrencyPipe } from "@angular/common";



export function getHostName() {
  var host = window.location.hostname.split(".")[0].toLowerCase();
  return host;
}

const parkerRoutes: Routes = [
  {
    path: "", component: ParkersComponent, pathMatch: "full",
    canActivate: [AuthorizeGuard], data: { title: "Home" }
  },
  { path: "**", component: NotfoundComponent, canActivate: [AuthorizeGuard], data: { title: "Page Not Found" } },
];

const adminRoutes: Routes = [
  {
    path: 'administration', component: AdministrationComponent,
    canActivate: [AuthorizeGuard], data: { title: "Administration" }
  },
  {
    canActivate: [AuthorizeGuard],
    path: 'portal', component: MainComponent,
    data: { title: "Parking Manager" }
  },
  {
    path: 'portal/:id', component: ParkerDetailComponent,
    canActivate: [AuthorizeGuard], data: { title: "Parker Details" }
  },
  { path: '', redirectTo: 'portal', pathMatch: "full" },
  {
    path: '**', component: NotfoundComponent,
    canActivate: [AuthorizeGuard], data: { title: "Page Not Found" }
  },
];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ApiAuthorizationModule,
    SharedModuleModule,
    AdminsModule,
    ParkersModule,
    RouterModule.forRoot(
      getHostName().includes(HostNames.ParkingAdmin) ? adminRoutes : parkerRoutes,
      {
        relativeLinkResolution: "legacy",
        //preloadingStrategy: RoleBasedPreloader,
      }
    )
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
